@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100dvh;
  background-image: radial-gradient(
    rgb(4, 6, 2) 1%,
    rgb(4, 13, 4) 50%,
    rgb(0, 0, 0) 67%,
    rgb(1, 1, 1) 100%
  );
}
